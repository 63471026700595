@use "@angular/material" as mat;
@use "./nw-palette";

@include mat.core();

$nw-primary: mat.define-palette(nw-palette.$palette, 500);
$nw-accent: mat.define-palette(nw-palette.$palette, 800);

$nw-primary-dark-form-field: mat.define-palette(nw-palette.$palette, 300);

$nw-theme: mat.define-light-theme(
  (
    color: (
      primary: $nw-primary,
      accent: $nw-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

$nw-theme: mat.define-dark-theme(
  (
    color: (
      primary: $nw-primary,
      accent: $nw-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

$nw-theme-form-field: mat.define-dark-theme(
  (
    color: (
      primary: $nw-primary-dark-form-field,
      accent: $nw-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

@include mat.button-theme($nw-theme);
@include mat.form-field-theme($nw-theme-form-field);
@include mat.checkbox-theme($nw-theme);