$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$background: #000000;
$primary: #3c2263;
$secondary:#684934;
$light-primary-text: white;
$hyper-link: rgb(0, 153, 255);
$accent-color: #ff4081;



$palette: (
  50: #ede7f6,
  100: #d1c4e9, //background
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: $primary, //primary color
  600: $secondary, //secondary color
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  A100: #b388ff,
  A200: #7c4dff,
  A400: #651fff,
  A700: #6200ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
