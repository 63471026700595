@use "./nw-palette";
// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

// MDB SCSS
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Inter", sans-serif;
  background-color: nw-palette.$background;
}

.snackbar--success {
  background-color: #81c784; // Lighter green
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.snackbar--error {
  background-color: #e57373; // Lighter red
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.snackbar--icon {
  margin-right: 8px;
}
